import titleMessageModal from '@/legacy/modals/styled/titleMessage';

class NegativeBalanceModal {
  // Because SelectCompany can be called multiple times (ex: during invitation) we want to ensure we never show more than 1 of these modals
  public static isVisible = false;

  public static show(): JQueryPromise<boolean> {
    if (NegativeBalanceModal.isVisible) {
      return undefined;
    }
    NegativeBalanceModal.isVisible = true;

    return titleMessageModal
      .show({
        title: 'Account Problem',
        message:
          'This company currently has a negative Plooto Instant balance. Please add funds to the Plooto Instant account to cover this amount owed to Plooto. Other transactions may stop processing for your company until this amount is paid.',
        svgIconSymbolLarge: 'svg-warning-large',
      })
      .then((response) => {
        NegativeBalanceModal.isVisible = false;

        return response;
      });
  }
}

export default NegativeBalanceModal;
