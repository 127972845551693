import app from 'durandal/app';
import * as segmentAnalytics from 'segmentAnalytics';

import { FEATURE_MAP } from '@/config/env';

import * as companyService from './services/company';
import CompanyEventService from './services/companyEventService';

/**
 * Global Durandal app event handlers.
 */
const initializeAppEvents = (): void => {
  app.on('company:emitService').then((eventType: string, payload: unknown) => {
    CompanyEventService.emit(eventType, payload);
  });

  app.on('companyProfile:companyChanged').then(() => {
    const activePrefix = localStorage.ROUTE_PREFIX;
    const loadableActiveFeatureNames = getActiveFeatureNames();
    const hasLoadableActiveFeature = loadableActiveFeatureNames.length > 0;

    if (!hasLoadableActiveFeature) {
      if (activePrefix) {
        localStorage.removeItem('ROUTE_PREFIX');
        tryNavigateAndReload();
      }
      return;
    }

    const moduleToLoad = loadableActiveFeatureNames[0];

    if (loadableActiveFeatureNames.length > 1) {
      console.warn(`More than one loadableModule, picked: ${moduleToLoad}.`);
    }

    if (activePrefix !== moduleToLoad) {
      localStorage.setItem('ROUTE_PREFIX', moduleToLoad);
      tryNavigateAndReload();
    }

    function getActiveFeatureNames() {
      const loadableFeatureModules: { [key: string]: string } = Object.entries(FEATURE_MAP || {})
        .filter((record) => record && record[1]?.prefix)
        .reduce((map, [featureName, { prefix }]) => ({ ...map, [featureName]: prefix }), {});

      return (
        (companyService.Instance.membership().featuresInTesting() || [])
          .filter((feature) => feature.active)
          // FIXME
          // @ts-expect-error: `featureName` doesn't exist in `FeaturesInTesting` type
          .map(({ name, featureName }) => featureName || name)
          .filter((featureName) => !!loadableFeatureModules[featureName])
      );
    }

    function tryNavigateAndReload() {
      const currentLocation = `${window.location.pathname}${window.location.hash}`;
      const notReloadSafeLocations = [
        // reload causes user being navigated back to the company selection screen (after already
        // selecting it)
        '/#user/company/select',
        // reload causes a duplicate API request being sent to create a company child node
        '/#user/tutorials/complete',
      ];

      if (notReloadSafeLocations.includes(currentLocation)) {
        window.location.pathname = '/';
        window.location.hash = '#user';
      }

      window.location.reload();
    }
  });

  app.on('user:signedIn').then((context) => {
    if (context.userId) {
      const traits = { email: context.email };
      segmentAnalytics.identify(context.userId, traits);
    }
  });
};

export default initializeAppEvents;
