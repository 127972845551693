import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import * as ko from 'knockout';
import { MessageDescriptor } from '@formatjs/intl';
import { isPlainObject, mapValues, times } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import XDate from 'xdate';

import ReactKnockoutTooltip from '@/legacy/features/react/tooltips/KnockoutTooltip';
import withPlootoPlatformProvider from '@/providers/withPlootoPlatformProvider';
import I18nService, { Instance as i18nService } from '@/legacy/services/i18n';
import CurrencyService from '@/features/financial/services/currencyService';
import { flushSync } from 'react-dom';
import {
  ApiDateFormat,
  DisplayDateFormat,
  formatLocalDate,
  formatServerDate,
} from '@/utils/formatDate';
import CurrencyCode from '@/features/financial/types/CurrencyCode';
import { DisplayMoneyFormat, formatMoney } from '@/utils/formatMoney';

const withDatePicker = async <T = void>(thunk: () => T) => {
  await import('bootstrap/datepicker');
  thunk();
};

const withFlexSelect = async <T = void>(thunk: () => T) => {
  await import('jquery.flexselect');
  thunk();
};

class KnockoutNewLineToBreak implements KnockoutBindingHandler {
  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const textValue = ko.utils.unwrapObservable(valueAccessor());

    const escapedHtml = $('<div/>').text(textValue).html();

    let field = escapedHtml;
    field = field.replace(/\r\n/g, '<br />');
    field = field.replace(/\n/g, '<br />');
    field = field.replace(/\r/g, '<br />');

    return ko.bindingHandlers.html.update?.(
      element,
      () => field,
      allBindingsAccessor,
      viewModel,
      bindingContext
    );
  }
}

/** Coerces sloppy Knockout values to Date or null. */
function coerceToDateOrDefault(value: Date | XDate | string | null | undefined): Date | null {
  if (value instanceof Date) {
    return value;
  }
  if (value instanceof XDate) {
    return value.toDate();
  }
  if (typeof value === 'string' && value.length > 0) {
    const dateValue = new Date(value);
    if (Number.isFinite(dateValue.getTime())) {
      return dateValue;
    }
  }
  return null;
}

/** Object in the form { "DisplayDateFormat.ShortDate": DisplayDateFormat.ShortDate } */
const BoundDateFormats = Object.fromEntries([
  ...Object.entries(DisplayDateFormat).map(([key, value]) => [`DisplayDateFormat.${key}`, value]),
  ...Object.entries(ApiDateFormat).map(([key, value]) => [`ApiDateFormat.${key}`, value]),
]);

/** Object in the form { "DisplayMoneyFormat.Long": DisplayMoneyFormat.Long } */
const BoundMoneyFormats = Object.fromEntries(
  Object.entries(DisplayMoneyFormat).map(([key, value]) => [`DisplayMoneyFormat.${key}`, value])
);

class KnockoutFormatLocalDate implements KnockoutBindingHandler {
  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const date = coerceToDateOrDefault(ko.utils.unwrapObservable(valueAccessor()));
    const formatKey = ko.utils.unwrapObservable(allBindingsAccessor().format);
    const format = BoundDateFormats[formatKey] ?? DisplayDateFormat.ShortDate;
    const formatted = date ? formatLocalDate(date, format) : '';
    ko.bindingHandlers.text.update?.(
      element,
      () => formatted,
      allBindingsAccessor,
      viewModel,
      bindingContext
    );
  }
}

class KnockoutFormatServerDate implements KnockoutBindingHandler {
  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const date = coerceToDateOrDefault(ko.utils.unwrapObservable(valueAccessor()));
    const formatKey = ko.utils.unwrapObservable(allBindingsAccessor().format);
    const format = BoundDateFormats[formatKey] ?? DisplayDateFormat.ShortDate;
    const formatted = date ? formatServerDate(date, format) : '';
    ko.bindingHandlers.text.update?.(
      element,
      () => formatted,
      allBindingsAccessor,
      viewModel,
      bindingContext
    );
  }
}

class KnockoutIfAnimationSlide implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const value = valueAccessor();

    const animationStartState = ko.unwrap(value);

    const dataContainerSelector = $(element).attr('data-animation-container-selector');
    if (dataContainerSelector && animationStartState) {
      $(element).closest(dataContainerSelector).addClass('animation-slide-expanded');
    }

    $(element).toggle(animationStartState); // Use "unwrapObservable" so we can handle values that may or may not be observable

    if (animationStartState) {
      $(element).removeClass('collapsed');
      $(element).addClass('expanded');
    } else {
      $(element).removeClass('expanded');
      $(element).addClass('collapsed');
    }
  }

  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    // Whenever the value subsequently changes, slowly fade the element in or out
    const value = valueAccessor();

    let animationSlideContainer;
    const dataContainerSelector = $(element).attr('data-animation-container-selector');
    if (dataContainerSelector) {
      animationSlideContainer = $(element).closest(dataContainerSelector);
      animationSlideContainer.addClass('animation-slide-animating');
    }

    if (value) {
      $(element).removeClass('collapsed');
      $(element).addClass('expanded');
    } else {
      $(element).removeClass('expanded');
      $(element).addClass('collapsed');
    }

    // eslint-disable-next-line no-unused-expressions
    ko.unwrap(value)
      ? $(element).slideDown(400, () => {
          if (dataContainerSelector) {
            animationSlideContainer.addClass('animation-slide-expanded');
            animationSlideContainer.removeClass('animation-slide-animating');
          }
        })
      : $(element).slideUp(400, () => {
          if (dataContainerSelector) {
            animationSlideContainer.removeClass('animation-slide-expanded');
            animationSlideContainer.removeClass('animation-slide-animating');
          }
        });
  }
}

class KnockoutDatepicker implements KnockoutBindingHandler {
  private static readonly throttledSubscriptionFieldName = 'observableChangedSubscription';

  public static setDateInput($el: JQuery, dateValue: Date, options: any) {
    const valueXDate = dateValue == null ? null : new XDate(dateValue);
    if (valueXDate) {
      // cant schedule days that are on weekends
      if (options.daysOfWeekDisabled != '') {
        while (valueXDate.getDay() == 0 || valueXDate.getDay() == 6) {
          valueXDate.addDays(1);
        }
      }

      $el.val(formatLocalDate(valueXDate.toDate(), DisplayDateFormat.ShortDate));
    }
  }

  public static getOptions(allBindings: any) {
    const options = { ...allBindings.datepickerOptions };
    if (options.daysOfWeekDisabled == undefined) {
      options.daysOfWeekDisabled = '0,6';
    }
    options.keyboardNavigation = false;
    options.disableTouchKeyboard = true;
    return options;
  }

  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const options = KnockoutDatepicker.getOptions(allBindingsAccessor());

    // added a check for null date values
    const dateValueSource = valueAccessor();
    let dateValue = ko.utils.unwrapObservable<Date>(dateValueSource.object[dateValueSource.key]); // the object that we're passing could be an observable

    const $el = $(element) as any;

    KnockoutDatepicker.bindObservableChanged(
      element,
      dateValueSource.object[dateValueSource.key],
      allBindingsAccessor()
    );

    // handle disposal (if KO removes by the template binding)
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $el.datepicker?.('destroy');
      element[KnockoutDatepicker.throttledSubscriptionFieldName]?.dispose();
    });

    if (dateValue) {
      KnockoutDatepicker.setDateInput($el, dateValue, options);
    }
    // eslint-disable-next-line consistent-return
    $el.focus((e) => {
      if ($el.data('datepicker-bound')) {
        e.preventDefault();
        return false;
      }
      $el.data('datepicker-bound', true);
      $el.attr('autocomplete', 'off');

      if ($el.data('initial-value')) {
        dateValue = $el.data('initial-value');
      }

      let valueXDate = dateValue == null ? null : new XDate(dateValue);

      withDatePicker(() => {
        // initialize datepicker with some optional options
        const dp = $el.datepicker(options);

        // handle the field changing
        dp.on('changeDate', () => {
          let newValue = $el.datepicker('getDate') as Date;

          const observableSource = valueAccessor();
          const wrappedValue = observableSource.object[observableSource.key]; // the object that we're passing could be an observable
          const currentValue = ko.utils.unwrapObservable<Date>(wrappedValue);

          if (currentValue?.valueOf() === newValue?.valueOf()) return;

          if (!newValue) {
            newValue = options.startDate;
          }

          // If a field is empty and there is no startDate specificed that means the field is not manditory so we'll want to set it to null
          if (!newValue) {
            if ($.isFunction(wrappedValue)) {
              observableSource.object[observableSource.key](null); // for observables
            } else {
              observableSource.object[observableSource.key] = null; // for non-observables
            }
            return;
          }

          newValue.setHours(0);
          newValue.setMinutes(0);
          newValue.setSeconds(0);

          valueXDate = new XDate(newValue);
          if (
            options.daysOfWeekDisabled != '' &&
            valueXDate &&
            (valueXDate.getDay() == 0 || valueXDate.getDay() == 6)
          ) {
            const oldValue = currentValue;
            if (oldValue != null) {
              $el.datepicker(
                'setDate',
                new Date(oldValue.getFullYear(), oldValue.getMonth(), oldValue.getDate())
              );
            }
            return;
          }
          if (currentValue != newValue) {
            if ($.isFunction(wrappedValue)) {
              observableSource.object[observableSource.key](newValue); // for observables
            } else {
              observableSource.object[observableSource.key] = newValue; // for non-observables
            }
          }
        });

        if (options.startDate && valueXDate != null && valueXDate.toDate() < options.startDate) {
          valueXDate = new XDate(options.startDate);
        }
        if (valueXDate != null) {
          // cant schedule days that are on weekends
          if (options.daysOfWeekDisabled != '') {
            while (valueXDate.getDay() == 0 || valueXDate.getDay() == 6) {
              valueXDate.addDays(1);
            }
          }
          $el.datepicker(
            'setDate',
            new Date(
              valueXDate.toDate().getFullYear(),
              valueXDate.toDate().getMonth(),
              valueXDate.toDate().getDate()
            )
          );
        }
        dp.trigger('changeDate'); // this will trigger initial date change (so we can pickup the initial value)
        $el.datepicker('show');
      });
    });
  }

  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const valueSource = valueAccessor();
    let value = ko.utils.unwrapObservable<any>(valueSource.object[valueSource.key]); // the object that we're passing could be an observable

    const $el = $(element) as any;

    // handle date data coming via json from Microsoft
    if (String(value).indexOf('/Date(') === 0) {
      const valueString = String(value);
      value = new Date(Number.parseInt(valueString.replace(/\/Date\((.*?)\)\//gi, '$1'), 10));
    }
    if ($el.data('datepicker-bound')) {
      const options = allBindingsAccessor().datepickerOptions || {};
      withDatePicker(() => {
        $el.datepicker('setStartDate', options.startDate);
        const current = $el.datepicker('getDate') as any;

        if (value - current !== 0) {
          $el.datepicker('setDate', value);
        }
      });
    } else {
      $el.data('initial-value', value);

      const options = allBindingsAccessor().datepickerOptions || {};
      KnockoutDatepicker.setDateInput($el, value, options);
    }
  }

  private static bindObservableChanged(element, valueObservable, { observableChanged }) {
    if (!valueObservable || !observableChanged || !ko.isObservable(valueObservable)) return;

    element[this.throttledSubscriptionFieldName] = ko
      .computed(() => valueObservable())
      .extend({
        throttle: 1,
      })
      .subscribe((newValue) => {
        observableChanged(newValue);
      });
  }
}

class KnockoutValidation implements KnockoutBindingHandler {
  /**
   * Handle validation error message. Can be either string or FormatJS MessageDescriptor.
   *
   * @param message Error message to display
   * @returns Error message
   */
  private static handleMessage(message: string | MessageDescriptor | undefined | null) {
    if (!message) {
      return undefined;
    }

    if (typeof message === 'string') {
      return message;
    }

    if (I18nService.isMessageDescriptor(message)) {
      const formattedMessage = ko.computed(() => i18nService.formatMessage(message));
      return formattedMessage();
    }

    throw new Error('Invalid message type');
  }

  private static displayValidationResult($el, result): void {
    // placeholder
  }

  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const $el = $(element);
    const $form = $el.parents('form:first');
    const rules = valueAccessor() as Array<any>;

    $el.keyup(() => {
      $el.removeClass('error');
      $el.removeClass('success');

      $el.parent().find('ul[name=errors]').remove();
    });
    $el.bind('_validate', (event) => {
      if ($el.hasClass('kodisabled')) {
        return undefined;
      }

      const value: string = $el.val();
      const requiredRegEx = /^(?!\s*$).+/;
      let isRequired = false;
      for (let i = 0; i < rules.length; i += 1) {
        const expression = rules[i][0];
        if (typeof expression === 'string' && expression == 'required') {
          isRequired = true;
          break;
        }
      }

      let errorMessage: string | undefined = undefined;
      for (let i = 0; i < rules.length; i += 1) {
        let expression = rules[i][0];
        const message = rules[i][1];
        const forcedType = rules[i][2];
        if (typeof expression === 'object' && expression.constructor == RegExp) {
          if (forcedType) {
            expression = new RegExp(expression);
          }
          if (!expression.test(value)) {
            errorMessage = KnockoutValidation.handleMessage(message);
            break;
          }
        } else if (typeof expression === 'string' && forcedType && forcedType == 'RegExp') {
          if (!new RegExp(expression).test(value)) {
            errorMessage = message;
            break;
          }
        } else if (typeof expression === 'string' && expression.substr(0, 2) == '==') {
          const elementName = $.trim(expression.substr(2));
          const elementValue = $form.find(`[name=${elementName}]`).first().val();
          if (elementValue === undefined) {
            // eslint-disable-next-line no-continue
            continue;
          }

          if (elementValue != value) {
            errorMessage = message;
            break;
          }
        } else if (typeof expression === 'string') {
          if (expression == 'required') {
            if (!requiredRegEx.test(value)) {
              errorMessage = message;
              break;
            }
          }
        } else if (typeof expression === 'function') {
          const retVal = expression.call(viewModel, $el);
          if (retVal === true || retVal === undefined) {
            // eslint-disable-next-line no-continue
            continue;
          }

          if (message === undefined) {
            errorMessage = retVal;
            break;
          } else {
            errorMessage = message;
            break;
          }
        }
      }
      return KnockoutValidation.handleMessage(errorMessage);
    });
    $el.focusout(() => {
      $el.triggerHandler('_validate');
    });
  }
}

class KnockoutTooltip implements KnockoutBindingHandler {
  private static reactRootsMap: WeakMap<HTMLElement, ReactDOM.Root> = new WeakMap();

  static containerMap: Map<HTMLElement, HTMLElement> = new Map();

  private static getContainer(element: HTMLElement) {
    return KnockoutTooltip.containerMap.get(element);
  }

  private static setContainer(element: HTMLElement, container: HTMLElement): void {
    KnockoutTooltip.containerMap.set(element, container);
  }

  public static init(element: HTMLElement, valueAccessor: () => string): void {
    // Create a container element for React.
    const container = KnockoutTooltip.getContainer(element) ?? document.createElement('div');

    if (!KnockoutTooltip.getContainer(element)) {
      // Make element "transparent".
      container.setAttribute(
        'style',
        'display:contents;max-height:min-content;max-width:min-content;'
      );

      // Set container if it didn't exist.
      KnockoutTooltip.setContainer(element, container);
    }

    // Get tooltip text.
    const valueUnwrapped = ko.utils.unwrapObservable(valueAccessor());

    // Replace the old element with the container.
    const oldElement = element.parentNode?.replaceChild(container, element);

    // Fill container with old element.
    if (oldElement != null) {
      container.appendChild(oldElement);
    }

    // Remove the React component on Durandal component disposal.
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      KnockoutTooltip.reactRootsMap.get(container)?.unmount();
      KnockoutTooltip.reactRootsMap.delete(container);
    });
  }

  public static update(element: HTMLElement, valueAccessor: () => string): void {
    const container = KnockoutTooltip.getContainer(element);

    // Get tooltip text.
    const valueUnwrapped = ko.utils.unwrapObservable(valueAccessor());

    if (valueUnwrapped == null) return;
    if (container == null) return;

    // Render the new tooltip in the container.
    let root = KnockoutTooltip.reactRootsMap.get(container);
    if (root == null) {
      root = ReactDOM.createRoot(container, { identifierPrefix: uuid() });
      KnockoutTooltip.reactRootsMap.set(container, root);
    }
    // Force React to render synchronously so that element is finalized before Knockout moves on to
    // the next binding, otherwise they will operate with incomplete nodes. For example, both
    // styledOption and tooltip do this parent switcheroo stuff, and need to be serialized.
    flushSync(() => {
      root!.render(
        React.createElement(withPlootoPlatformProvider(ReactKnockoutTooltip), {
          anchorEl: element,
          title: valueUnwrapped,
        })
      );
    });
  }
}

/**
 * Knockout binding handler for i18n HTML text content.
 */
class KnockoutI18n implements KnockoutBindingHandler {
  public static update(
    element: HTMLElement,
    valueAccessor: () => MessageDescriptor,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const messageDescriptor = ko.utils.unwrapObservable<MessageDescriptor>(valueAccessor());
    const values = ko.utils.unwrapObservable(allBindingsAccessor().values) ?? {};
    const message = ko.pureComputed(() => i18nService.formatMessage(messageDescriptor, values));

    ko.bindingHandlers.text.update?.(
      element,
      () => message(),
      allBindingsAccessor,
      viewModel,
      bindingContext
    );
  }
}

/**
 * Knockout binding handler for i18n HTML attribute values.
 */
class KnockoutI18nAttr implements KnockoutBindingHandler {
  public static update(
    element: HTMLElement,
    valueAccessor: () => { [attribute: string]: MessageDescriptor },
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const attributes =
      ko.utils.unwrapObservable<Record<string, MessageDescriptor>>(valueAccessor());
    const attributesAsObservables = mapValues(attributes, (messageDescriptor) =>
      ko.pureComputed(() => i18nService.formatMessage(messageDescriptor))
    );
    ko.bindingHandlers.attr.update?.(
      element,
      () => attributesAsObservables,
      allBindingsAccessor,
      viewModel,
      bindingContext
    );
  }
}

class KnockoutFocus implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const $el = $(element);
    const focus = ko.utils.unwrapObservable<any>(allBindingsAccessor().focus);

    $el.focus((event) => {
      if (!focus()) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
      return undefined;
    });
  }
}
class KnockoutAutofocus implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const $el = $(element);
    const value = ko.utils.unwrapObservable<boolean>(valueAccessor()) || false;

    if (value) {
      $el.attr('autofocus', 'autofocus').focus();
    }
  }
}
class KnockoutTogglePasswordMask implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const useMask = ko.utils.unwrapObservable<string>(valueAccessor()) || false;
    if (!useMask) {
      return;
    }
    const $element = $(element);
    $element.attr('autocomplete', 'new-password');
    $element.attr('type', 'password');

    const wrapElement = $('<div class="password-mask-container" />');
    $element.wrap(wrapElement);

    const toggleIcon = $(
      '<span class="text-dark-slate" style="cursor: pointer;"><i class="fa fa-eye" aria-hidden="true"></i></span>'
    );

    toggleIcon.click((e) => {
      e.preventDefault();

      $element.attr('type', $element.attr('type') === 'password' ? 'text' : 'password');

      return false;
    });

    toggleIcon.tooltip({
      title: 'Click to toggle password masking',
      placement: 'top',
      template:
        '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner" style="width:132px;"></div></div>',
    });

    const toggleElement = $('<div class="password-mask-toggle" />');
    toggleElement.append(toggleIcon);
    toggleElement.insertAfter($element);

    $element.on('keyup', () => {
      toggleElement.toggle(!!$element.val());
    });
    if ($element.val()) {
      toggleElement.show();
    }
  }
}

class KnockoutPlootoUniqueName implements KnockoutBindingHandler {
  public static inputNumber = 0;

  public static inputDate = new Date();

  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    $(element).attr(
      'name',
      `plInput_${KnockoutPlootoUniqueName.inputDate.getTime()}_${
        KnockoutPlootoUniqueName.inputNumber
      }`
    );
    KnockoutPlootoUniqueName.inputNumber += 1;
  }
}
class KnockoutFlexSelect implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const $el = $(element);

    $el.addClass('flexselect').addClass('form-control');
    $el.attr({
      spellcheck: 'false',
      type: 'text',
      autocomplete: 'off',
    });
    $el.wrap('<div class="flexselect-container" />');

    const flexSelectOptions = ko.utils.unwrapObservable<any>(valueAccessor());
    const value = ko.utils.unwrapObservable<any>(flexSelectOptions.value);
    withFlexSelect(() => {
      $el.flexselect(flexSelectOptions);

      // Removes HTML dropdown
      ko.utils.domNodeDisposal.addDisposeCallback($el.get(0), () => {
        $el.flexselect('destroy');
      });
      ($el as any).flexselect('setInitialValue', value); // .setValue(value)
    });
  }

  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const flexSelectOptions = ko.utils.unwrapObservable<any>(valueAccessor());
    const value = ko.utils.unwrapObservable<any>(flexSelectOptions.value);

    const $el = $(element);
    withFlexSelect(() => {
      $el.flexselect('updateValue', value); // .setValue(value)
    });
  }
}

class KnockoutDropdown implements KnockoutBindingHandler {
  private static POPULATED_TAG = 'populated';

  private static LAST_VALUE_TAG = 'last-value';

  private static populateItems(
    $el: JQuery,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ) {
    let valueUnwrapped = ko.utils.unwrapObservable<any>(valueAccessor());
    while (valueUnwrapped !== undefined && ko.isObservable(valueUnwrapped)) {
      valueUnwrapped = ko.utils.unwrapObservable(valueUnwrapped());
    }
    const optionsCaption = ko.utils.unwrapObservable<string>(allBindingsAccessor().optionsCaption);
    const optionsText = ko.utils.unwrapObservable<string>(allBindingsAccessor().optionsText);
    const optionsValue = ko.utils.unwrapObservable<string>(allBindingsAccessor().optionsValue);
    const currentValue = $el.data(KnockoutDropdown.LAST_VALUE_TAG);
    const optionsFilter = ko.utils.unwrapObservable<any>(allBindingsAccessor().optionsFilter);

    let itemsLength = 0;
    if (valueUnwrapped !== undefined && $.isArray(valueUnwrapped)) {
      if (optionsFilter) {
        const filteredItems = optionsFilter(valueUnwrapped);
        if (filteredItems != null) {
          valueUnwrapped = filteredItems;
        }
      }

      itemsLength = valueUnwrapped.length;
    }

    $el.empty();
    if (optionsCaption != undefined) {
      $el.append(
        $('<option>', {
          text: optionsCaption,
          value: '',
        })
      );
    }

    if (itemsLength > 0) {
      let valueChanged = false;
      $.each(valueUnwrapped, (index, item) => {
        $el.data(KnockoutDropdown.POPULATED_TAG, 'true');
        const text = ko.utils.unwrapObservable<any>(item[optionsText]);
        const value = ko.utils.unwrapObservable<any>(item[optionsValue]);

        let selected;
        if (value == currentValue) {
          selected = 'selected';
          valueChanged = true;
        }

        $el.append(
          $('<option>', {
            selected,
            text,
            value,
          })
        );
      });
      if (valueChanged) $el.trigger('change');
    }
  }

  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const $el = $(element);
    const valueOutput = allBindingsAccessor().value;
    const optionsCaption = ko.utils.unwrapObservable<string>(allBindingsAccessor().optionsCaption);
    let lastValue = ko.utils.unwrapObservable<any>(allBindingsAccessor().value);
    const observable = allBindingsAccessor().value;
    while (lastValue !== undefined && ko.isObservable(lastValue)) {
      lastValue = ko.utils.unwrapObservable(lastValue());
    }
    $el.data(KnockoutDropdown.LAST_VALUE_TAG, lastValue);

    ko.utils.registerEventHandler(element, 'change', () => {
      const $changeEl = $(element);
      let newValue = $changeEl.val();
      const oldValue = $changeEl.data(KnockoutDropdown.LAST_VALUE_TAG);
      if (newValue == '') newValue = undefined;

      if (
        $changeEl.data(KnockoutDropdown.POPULATED_TAG) &&
        $changeEl.data(KnockoutDropdown.POPULATED_TAG).toString() == 'true'
      ) {
        // values remained the same, ignore
        if (newValue == oldValue) {
          return;
        }
        // update last value if it has changed
        if (newValue !== undefined && newValue !== null) {
          $changeEl.data(KnockoutDropdown.LAST_VALUE_TAG, newValue);
        }
        if (ko.isObservable(observable)) {
          observable(newValue);
        }
      }
    });
    KnockoutDropdown.populateItems(
      $el,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    );
  }

  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const $el = $(element);
    KnockoutDropdown.populateItems(
      $el,
      valueAccessor,
      allBindingsAccessor,
      viewModel,
      bindingContext
    );
  }
}

class KnockoutDisableMouseWheel implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const $el = $(element);
    $el.bind('mousewheel', () => {
      $el.blur();
    });
  }
}

class KnockoutCurrency implements KnockoutBindingHandler {
  public static symbol = '$';

  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    return ko.bindingHandlers.html.update?.(
      element,
      () => {
        let value: any = ko.utils.unwrapObservable(valueAccessor());
        if (Array.isArray(value)) {
          value = value.map((x) => ko.utils.unwrapObservable(x));
        } else {
          value = [value, CurrencyCode.None];
        }
        const [amount, currency] = value;
        const formatKey = ko.utils.unwrapObservable(allBindingsAccessor().format);
        const format = BoundMoneyFormats[formatKey] ?? DisplayMoneyFormat.Long;
        return formatMoney(amount, currency, format);
      },
      allBindingsAccessor,
      viewModel,
      bindingContext
    );
  }
}

class KnockoutTitle implements KnockoutBindingHandler {
  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    return ko.bindingHandlers.attr.update?.(
      element,
      () => ({ title: valueAccessor() }),
      allBindingsAccessor,
      viewModel,
      bindingContext
    );
  }
}

class KnockoutRowSelect implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const $el = $(element);
    let downPos;
    let originalSelection;
    let mouseActivityTime;

    $el.click((e) => {
      if (
        e.target &&
        ($(e.target).closest('.no-row-select').length > 0 || $(e.target).hasClass('no-row-select'))
      ) {
        return true;
      }

      if (e.target && $(e.target).closest('.no-row-select').length > 0) {
        return true;
      }

      if (mouseActivityTime != undefined && (new Date() as any) - mouseActivityTime < 100) {
        return false;
      }

      const callback = valueAccessor();
      if (callback === undefined) {
        return false;
      }

      callback.call(bindingContext, viewModel);
      return undefined;
    });
    $el.on('select', () => {
      mouseActivityTime = new Date();
      const callback = valueAccessor();
      if (callback === undefined) {
        return false;
      }

      callback.call(bindingContext, viewModel);
      return undefined;
    });
    $el.mousedown((e) => {
      mouseActivityTime = new Date();
      if (window.getSelection) {
        originalSelection = window.getSelection()?.toString();
      } else if ((document as any).selection && (document as any).selection.type != 'Control') {
        originalSelection = (document as any).selection.createRange().text;
      }
      downPos = { x: e.pageX, y: e.pageY };
    });
    $el.mouseup((e) => {
      if (
        e.target &&
        ($(e.target).closest('.no-row-select').length > 0 || $(e.target).hasClass('no-row-select'))
      ) {
        return true;
      }

      mouseActivityTime = new Date();

      if (e.target && $(e.target).closest('.no-row-select').length > 0) {
        return true;
      }

      if (e.button !== 0 && e.button !== 1) {
        return undefined;
      }
      const targetType = (e.target as HTMLElement).tagName;

      if (targetType === 'A') {
        return undefined;
      }

      e.stopPropagation();
      e.preventDefault();

      let newSelection;
      if (window.getSelection) {
        newSelection = window.getSelection()?.toString();
      } else if ((document as any).selection && (document as any).selection.type != 'Control') {
        newSelection = (document as any).selection.createRange().text;
      }
      if (downPos) {
        const clickDistance = (e.pageX - downPos.x) ** 2 + (e.pageY - downPos.y) ** 2;
        if (newSelection === originalSelection || clickDistance < 500) {
          const callback = valueAccessor();
          if (callback === undefined) return false;

          callback.call(bindingContext, viewModel);
        }
      }
      return false;
    });
  }
}

class KnockoutHidden implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const value = ko.utils.unwrapObservable<string>(valueAccessor());
    const $el = $(element);

    if (ko.unwrap(value)) {
      $el.css('visibility', 'hidden');
    } else {
      $el.css('visibility', 'visible');
    }
  }

  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const value = ko.utils.unwrapObservable<string>(valueAccessor());
    const $el = $(element);
    if (ko.unwrap(value)) {
      $el.css('visibility', 'hidden');
    } else {
      $el.css('visibility', 'visible');
    }
  }
}

function KnockoutDirtyFlag(root, isInitiallyDirty) {
  const _initialState = ko.observable(ko.toJSON(root));
  const _isInitiallyDirty = ko.observable(isInitiallyDirty);

  const result = function () {
    this.isDirty = ko.computed(() => _isInitiallyDirty() || _initialState() !== ko.toJSON(root));
    this.reset = function () {
      _initialState(ko.toJSON(root));
      _isInitiallyDirty(false);
    };
  };

  return result;
}

class KnockoutSelectOnClick implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const $el = $(element);
    $el.click(() => {
      $el.focus().select();
    });
  }
}
class KnockoutMinPrecision implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    // placeholder
  }
}

class KnockoutMathCeil implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const precisionDecimals = Number.parseInt(
      ko.utils.unwrapObservable(allBindingsAccessor().precisionDecimals),
      10
    );

    const $el = $(element);

    const formatMathCeil = () => {
      let elementValue = ko.utils.unwrapObservable<string>(valueAccessor()).toString();

      elementValue = elementValue.replace(/[^\d.-]/g, '');

      let floatValue = parseFloat(elementValue);
      if (Number.isNaN(floatValue) || !Number.isFinite(floatValue)) {
        floatValue = 0.0;
      }

      let roundedValue = floatValue.toFixed(precisionDecimals);
      if (roundedValue.indexOf('e') >= 0) roundedValue = '0.00';

      $el.text(roundedValue);
    };

    formatMathCeil();
  }
}

class KnockoutInputMoney implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const { object, key } = valueAccessor();
    const $el = $(element);

    const getValue = () => (typeof object[key] === 'function' ? object[key]() : object[key]);

    const setValue = (newValue: unknown) => {
      if (typeof object[key] === 'function') {
        object[key](newValue);
      } else {
        object[key] = newValue;
      }
    };

    const handleFocusIn = () => {
      let elementValue = $el.val();

      // remove zero dollars when entering dollar value
      if (elementValue == '0.00') {
        $el.val('');
        return;
      }

      // strip commas to have only decimals
      elementValue = elementValue.replaceAll(/,/g, '');
      $el.val(elementValue);
      $el.select();
    };

    const handleFocusOut = () => {
      const elementValue = formatMoney($el.val(), CurrencyCode.None, DisplayMoneyFormat.Short);

      // set element value
      $el.val(elementValue);

      // update value of the input box that is associated with this element
      setValue(elementValue);
    };

    $el.on('focusin', handleFocusIn);
    $el.on('focusout', handleFocusOut);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $el.off('focusout', handleFocusOut);
      $el.off('focusin', handleFocusIn);
    });

    $el.val(getValue());
    handleFocusOut();
  }
}

class KnockoutMaxPrecision implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    // placeholder
  }
}
class KnockoutJsBinding implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const returnValue = ko.utils.unwrapObservable<any>(allBindingsAccessor().precision);
  }
}

class KnockoutStyledOption implements KnockoutBindingHandler {
  public static init(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const value = valueAccessor();

    const $element = $(element);

    const isRadio = $element.attr('type') == 'radio';
    const radioValue = $element.attr('value');

    const $elementLabel = $element.parent();

    const elementType = $element.attr('type');

    $elementLabel.addClass('btn').addClass(`btn-${elementType}`).addClass('btn-styledOption');
    if (allBindingsAccessor().styledOptionHangingIndent) {
      $elementLabel.addClass('styledOption-hanging-indent');
    }

    const $btnGroupWrap = $('<div class="btn-group" data-toggle="buttons" />');
    if (allBindingsAccessor().styledOptionBtnGroupStyles) {
      $btnGroupWrap.css(allBindingsAccessor().styledOptionBtnGroupStyles);
    }
    $elementLabel.wrap($btnGroupWrap);

    $elementLabel.prepend($(`<i class="pl-icon pl-icon-2 pl-icon-${elementType}" ></i>`));

    $elementLabel.on('valueUpdated', () => {
      if (isRadio) {
        if (value() == radioValue) {
          $elementLabel.addClass('active');
        } else {
          $elementLabel.removeClass('active');
        }
      } else if (value()) {
        $elementLabel.addClass('active');
      } else {
        $elementLabel.removeClass('active');
      }
    });

    $elementLabel.click((e) => {
      e.preventDefault();

      if (
        $elementLabel.hasClass('disabled') ||
        $elementLabel.hasClass('pl-disabled') ||
        $elementLabel.closest('.form-submitting').length > 0
      ) {
        return false;
      }
      if (isRadio) {
        value(radioValue);
      } else {
        value(!value());
      }

      $elementLabel.trigger('valueUpdated');

      return false;
    });

    // For inital state
    $elementLabel.trigger('valueUpdated');
  }

  public static update(
    element: any,
    valueAccessor: () => any,
    allBindingsAccessor: KnockoutAllBindingsAccessor,
    viewModel: any,
    bindingContext: KnockoutBindingContext
  ): void {
    const $element = $(element);

    const $elementLabel = $element.parent();

    // For updated state
    $elementLabel.trigger('valueUpdated');
  }
}
class Bindings {
  constructor() {
    const bindings = ko.bindingHandlers as any;
    bindings.tooltip = KnockoutTooltip;
    bindings.i18n = KnockoutI18n;
    bindings.i18nAttr = KnockoutI18nAttr;
    bindings.focus = KnockoutFocus;
    bindings.autofocus = KnockoutAutofocus;
    bindings.datepicker = KnockoutDatepicker;
    bindings.styledOption = KnockoutStyledOption;
    bindings.formatLocalDate = KnockoutFormatLocalDate;
    bindings.formatServerDate = KnockoutFormatServerDate;
    bindings.ifAnimationSlide = KnockoutIfAnimationSlide;
    bindings.validation = KnockoutValidation;
    bindings.rowSelect = KnockoutRowSelect;
    bindings.nl2br = KnockoutNewLineToBreak;

    bindings.dropdown = KnockoutDropdown;
    bindings.disableMouseWheel = KnockoutDisableMouseWheel;
    bindings.currency = KnockoutCurrency;
    bindings.formatMoney = KnockoutCurrency;
    bindings.title = KnockoutTitle;
    bindings.flexSelect = KnockoutFlexSelect;
    bindings.hidden = KnockoutHidden;
    bindings.minPrecision = KnockoutMinPrecision;
    bindings.maxPrecision = KnockoutMaxPrecision;
    bindings.inputMoney = KnockoutInputMoney;
    bindings.mathCeil = KnockoutMathCeil;

    bindings.selectOnClick = KnockoutSelectOnClick;
    bindings.js = KnockoutJsBinding;
    bindings.togglePasswordMask = KnockoutTogglePasswordMask;
    bindings.uniqueName = KnockoutPlootoUniqueName;

    (ko as any).dirtyFlag = KnockoutDirtyFlag;
  }
}

// extenders
(ko.extenders as any).paging = (target, config) => {
  const { data } = config;
  const paging: any = {};
  let pageItems = 100;
  if (config.pageItems !== undefined) {
    pageItems = config.pageItems;
  }
  const currentPage: KnockoutObservable<number> = ko.observable(0);
  const sortProperty: KnockoutObservable<string> = ko.observable('');
  const asc: KnockoutObservable<boolean> = ko.observable(true);
  paging.items = ko.pureComputed({
    read: () => {
      const items = data();

      if (sortProperty() != '') {
        const sortPropertyValue = sortProperty();
        const sortPropertyElements = sortPropertyValue.split('.');
        let sortOrderMultiplier = 1;
        if (!asc()) sortOrderMultiplier = -1;
        items.sort((left, right): number => {
          let value1 = left;
          let value2 = right;

          for (let i = 0; i < sortPropertyElements.length; i += 1) {
            value1 = ko.utils.unwrapObservable(value1[sortPropertyElements[i]]);
            value2 = ko.utils.unwrapObservable(value2[sortPropertyElements[i]]);
          }

          if (value1 == value2) {
            if (sortPropertyValue == 'vendor.name') {
              // eslint-disable-next-line no-nested-ternary
              return left.number > right.number
                ? sortOrderMultiplier
                : left.number < right.number
                  ? -sortOrderMultiplier
                  : 0;
            }

            // eslint-disable-next-line no-nested-ternary
            return left.date > right.date
              ? sortOrderMultiplier
              : left.date < right.date
                ? -sortOrderMultiplier
                : 0;
          }
          // eslint-disable-next-line no-nested-ternary
          return value1 > value2 ? sortOrderMultiplier : value1 < value2 ? -sortOrderMultiplier : 0;
        });
      }
      return items.slice(currentPage() * pageItems, currentPage() * pageItems + pageItems);
    },
    deferEvaluation: true,
  });
  paging.empty = ko.pureComputed({
    read: () => data().length == 0,
    deferEvaluation: true,
  });
  paging.asc = ko.pureComputed({
    read: () => asc(),
    deferEvaluation: true,
  });
  paging.sort = ko.pureComputed({
    read: () => sortProperty(),
    write: (sortField: string) => {
      if (sortProperty() == sortField) {
        asc(!asc());
        return;
      }
      asc(true);
      sortProperty(sortField);
    },
    deferEvaluation: true,
  });
  paging.pages = ko.pureComputed({
    read: () => {
      const totalPages = Math.ceil(data().length / pageItems);
      const pages = times(totalPages, (i) => ({
        number: i + 1,
        id: i,
        active: i == currentPage(),
        activate: (thisPage: { id: number }) => {
          currentPage(thisPage.id);
        },
      }));
      return pages;
    },
    deferEvaluation: true,
  });
  paging.rangeStart = ko.pureComputed({
    read: () => currentPage() * pageItems,
    deferEvaluation: true,
  });
  paging.rangeEnd = ko.pureComputed({
    read: () => Math.min(data().length, (currentPage() + 1) * pageItems) - 1,
    deferEvaluation: true,
  });
  paging.totalResults = ko.pureComputed({
    read: () => data().length,
    deferEvaluation: true,
  });
  paging.isFirstPage = ko.pureComputed({
    read: () => currentPage() == 0,
    deferEvaluation: true,
  });
  paging.isLastPage = ko.pureComputed({
    read: () => {
      const totalPages = Math.floor(data().length / pageItems);
      return currentPage() == totalPages;
    },
    deferEvaluation: true,
  });

  paging.currentPage = currentPage;
  paging.pageItems = pageItems;

  return paging;
};
(ko.extenders as any).currencyFormat = (target, option) => {
  const result = ko.computed(() => {
    const targetValue = ko.utils.unwrapObservable<string>(target);
    if (targetValue) {
      const currencyCode = targetValue;
      const currency = CurrencyService.getCurrency(currencyCode as CurrencyCode);
      switch (option) {
        case 'name':
          return currency.name;
        case 'code':
          return currency.code;
      }
      return currencyCode;
    }
    return targetValue;
  });

  return result;
};

const Instance = new Bindings();
export { Bindings, Instance };
